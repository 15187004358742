<template>
  <div>
    <!-- 养老报告详情 -->
    <table class="table">
    <caption class="text-center">人员评估报告</caption>
    <tbody>
      <tr>
        <td class="td1111">
            <div class="userimg float-left">
                <img width="107" height="135" src="https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg" onerror="this.src = 'https://file.chinaylzl.com/pro/2016/other/defaultHead.jpg'">
            </div>
        </td>
        <td colspan="4">
            <div class="tableinfo">
                <div class="frow">
                    <div class="fcol w200">
                        <label> <span class="font14">姓</span> <span class="font14">名：</span>
                        </label> <label><span class="font14"><a>{{table.oldPersonName}}</a></span></label>
                    </div>
                    <div class="fcol">
                        <label>报告编号：</label> <label><span class="font14"><a>{{table.assessNo}}</a></span></label>
                    </div>

                </div>
                <div class="frow">
                    <div class="fcol w200">
                        <label>联系电话：</label> <label><span class="font14">{{table.oldPersonPhone}}</span></label>
                    </div>
                    <div class="fcol">
                        <label><span class="font14">性</span><span class="font14">别：</span></label> <label><span class="font14">{{table.oldPersonGender}}</span></label>
                    </div>
                </div>
                <div class="frow">
                    <div class="fcol">
                        <label>出生日期：</label> <label><span class="font14">{{table.oldPersonBirthTime}}</span></label>
                    </div>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td class="text-right"><span class="font14">评估机构：</span></td>
        <td class="text-left" colspan="2"><span class="font14">{{table.assessOrgName}}</span></td>
        <td class="text-right"><span class="font14">类型：</span></td>
        <td class="text-left"><span class="font14">{{table.assessType}}</span></td>
    </tr>
    <tr>
          <td class="text-right"><span class="font14">评估方案：</span></td>
          <td class="text-left" colspan="2"><span class="font14"><a href="#">{{table.plan}}</a></span></td>
          <td class="text-right"><span class="font14">评估员：</span></td>
          <td class="text-left">
              <span class="font14">{{table.assessorName}}</span><br>
              <!-- <span class="font14">-</span> -->
          </td>
    </tr>
    <tr>
          <tr>
      <td class="text-right" rowspan="4"><span class="font14">各项指标结果：</span>
      </td>
    </tr>
    <tr>
        <td class="text-left" colspan="2">
          <span class="font14">感知觉与沟通{{table.measureResults.feelLevel}}级</span>
        </td>
        <td class="text-right" style="border-right: 1px solid white">
          <span class="font14">精神状态{{table.measureResults.feelLevel}}级</span>
        </td>
        <td class="text-left"><span class="font14"></span></td>
    </tr>
    <tr>
        <td class="text-left" colspan="2">
          <span class="font14">日常生活活动{{table.measureResults. lifeLevel}}级</span>
        </td>
        <td class="text-right" style="border-right: 1px solid white">
          <span class="font14">社会参与{{table.measureResults.societyLevel}}级</span>
        </td>
        <td class="text-left"><span class="font14"></span></td>
    </tr>
    <tr></tr>
    <tr>
      <td class="text-right"><span class="font14">等级变更：</span></td>
      <td class="text-left" colspan="4">
        <span class="font14">{{table.levelChange}}</span>
      </td>
    </tr>
    <tr>
      <td class="text-right"><span class="font14">初步评估结果：</span></td>
      <td class="text-left" colspan="4">
        <span class="font14">
            <a>{{table.preEvalResult}}</a>
        </span>
      </td>
    </tr>

    <tr>
      <td class="text-right"><span class="font14">最终评估结果：</span></td>
      <td class="text-left" colspan="4">
        <span class="font14">{{table.finalEvalResult}}</span>
      </td>
    </tr>
    <tr>
      <td class="text-right"><span class="font14">审核意见：</span></td>
      <td class="text-left" colspan="4">
        <span class="font14">{{table.reviewComments}}</span>
      </td>
    </tr>
    <tr>
        <td class="text-right"><span class="font14">专家意见：</span></td>
        <td class="text-left" colspan="4"><span class="font14">{{table.expertComments}}</span>
        </td>
    </tr>
 <tr>
      <td colspan="5">
          <div class="bz">
              <header>注：老人能力初步等级划分标准：</header>
              <h2>1 能力完好</h2>
              <p> 日常生活活动、精神状态、感知觉与沟通分级均为0，社会参与分级为0或1。</p>
              <h2>2 轻度失能</h2>
              <p>日常生活活动分级为 0，但精神状态、感知觉与沟通中至少一项分级为1及以上，或社会参与的分级为2；或日常生活活动分级为1，精神状态、感知觉与沟通、社会参与中至少有一项的分级为0或1。</p>
             
              <h2>3 中度失能</h2>
              <p>日常生活活动分级为1，但精神状态、感知觉沟通、社会参与均为2，或有一项为3；或日常生活活动分级为2，且精神状态、感知觉与沟通、社会参与中有1~2项的分级为1或2。</p>
              <h2>4 重度失能</h2>
              <p>日常生活活动的分级为3；或日常生活活动、精神状态、感知觉与沟通、社会参与分级均为2；或日常生活活动分级为2，且精神状态、感知觉与沟通、社会参与中至少有一项分级为3</p>
          </div>
          
          
      </td>
    </tr>

    <tr>
        <!-- <td class="text-center" colspan="5">
            <div class="btngroup">
                <a class="blue" id="downloadReport">下载养老顾问服务报告</a>
            </div>
        </td> -->
    </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import api from '@/store/API/api'
import axios from "axios";
export default {
    components: {
    UploadImg
  },
    data () {
      return {
        table:{
            measureResults:{}
        }
      }
    },
    created(){
      console.log(this.$route);
      this.queryDetail()
    },
    methods:{
      ...mapActions([
        'assessListDetail'

    ]),
    queryDetail(){
        
        let params ={
            id:this.$route.query.id
        }
        console.log(params);
        this.assessListDetail(params).then(res=>{
            console.log(res);
            if(res.code==200){
                res.data.measureResults = JSON.parse(res.data.measureResults) 
                this.table = res.data
            }
            // console.log(JSON.parse(res.data.measureResults) );
        })
    }
    }
}
</script>

<style  scoped>
.servertable {
    position: relative;
}
.backwhite {
    background-color: #fff;
    width: 98%;
    padding: 40px 0 0 50px;
    float: left;
}
.table{
    background-color: transparent;
    max-width: 100%;
    margin-bottom: 20px;
    width: 98%;
    border-spacing: 0;
    border-collapse: collapse;

}
/* :deep .el-descriptions__header{
  display: flex;
  justify-content: center !important;

  font-size: 24px;
} */
/* :deep .el-descriptions__header .el-descriptions__title{
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    font-weight: 400;
}
.img{
  width: 150px;
  height: 150px;
} */
.table tr .td1111 {
    width: 170px;
    padding-left: 24px;
}
.td1111{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: #333;
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0;
    box-sizing: border-box;
    font-family: "Microsoft YaHei", "微软雅黑";
    list-style: none;
    outline: none;
    text-decoration: none;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
    font-size: 14px;
    height: 50px;
    border: 1px solid #e2e5e7;
    width: 170px;
    padding-left: 24px;
    
}
.float-left {
    float: left;
}
.tableinfo {
    margin-left: 1em;
}
.frow {
    width: 100%;
    display: flex;
    align-items: center;
    float: left;
    display: -moz-box;
    /* display: -webkit-box; */
    display: box;
    -moz-box-align: center;
    -webkit-box-align: center;
    display: -ms-flexbox;
    -ms-flex-align: center;
}
.tableinfo {
    margin-left: 1em;
}
.frow .fcol {
    float: left;
    /* height: 60px; */
    line-height: 60px;
    position: relative;
}
.w200 {
    width: 200px;
}
.font14 {
    font-size: 14px;
}
.table tr td {
    vertical-align: middle;
    font-size: 14px;
    height: 50px;
    border: 1px solid #e2e5e7;
}
.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
    /* border-top: 1px solid #ddd; */
}
.table caption {
    color: #488fe1;
    font-size: 24px;
    padding: 0;
    margin-bottom: 16px;
}
.table tr td {
    vertical-align: middle;
    font-size: 14px;
    height: 50px;
    border: 1px solid #e2e5e7;
}
.text-right {
    text-align: right;
}
.text-left {
    text-align: left;
}
table tr td .bz {
    color: #777676;
    line-height: 31px;
    padding-left: 17px;
    padding-right: 50px;
}
.table tr td .bz h2 {
    font-size: 18px;
    margin: 5px 0px;
    line-height: 1em;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
}
.table tr td .bz p {
    padding-left: 11px;
}
.table .btngroup {
    margin: 15px 0;
}
.table .btngroup .blue {
    background-color: #488fe1;
}
.table .btngroup a {
    color: white;
    font-size: 16px;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 6px;
    margin: 0 16px;
}
.text-center {
    text-align: center;
}
.table a, .servertable .table a:visited, .servertable .table a:active, .servertable .table a:link, .servertable .table a:hover {
    color: #5093E2;
    font-size: 14px;
}
.userimg img {
    border: 1px solid #e7e7e7;
    margin-bottom: 10px;
    margin-left: 5px;
}
.servertable .table .btngroup .blue:hover {
    background-color: #3183E3;
}
.servertable .table .btngroup a:hover {
    color: white;
    font-size: 16px;
}
.servertable .table tr td .bz header {
    vertical-align: middle;
    font-size: 14px;
    height: auto;
    border: none;
    padding: 0;
}
</style>  